<template>
  <AcaoItem titulo="Aprovação" :color="color"
            :historico="historico.historicosAprovacao">
    <template v-slot:default>
      <p>
        Clique em Aprovar para realizar a aprovação
        <br/>
        O processo deve estar em andamento
      </p>
    </template>
    <template v-slot:botao>
      <v-btn
          class="mt-1"
          :loading="loading"
          :disabled="!processo.podeFazerAcoes"
          color="primary"
          outlined
          @click="realizarAprovacao"
      >
        Aprovar
      </v-btn>
      <v-btn
          class="mt-1 ml-2"
          :disabled="!historico.historicosAprovacao"
          color="accent"
          outlined
          @click="visualizarAprovacao"
      >
        Visualizar
      </v-btn>
    </template>
    <template v-slot:extra>
      <v-dialog v-model="dialogResultado" width="600">
        <v-card key="resultado" v-if="dialogResultado">
          <v-card-title class="primary text-h6 white--text justify-center mb-2">
            Resultado Aprovação
          </v-card-title>
          <v-card-text class="overflow-y-auto">
            <v-alert :type="resultado.tipo" text outlined dense>
              {{ resultado.msg }}
            </v-alert>
            <v-sheet v-if="resultado.detalhes" outlined rounded>
              <v-virtual-scroll
                  :items="resultado.detalhes"
                  :item-height="25"
                  height="400"
              >
                <template v-slot:default="{ item }">
                  <v-list-item :key="item">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-virtual-scroll>
            </v-sheet>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialogResultado = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </AcaoItem>
</template>

<script>
import aprovacaoService from "@/services/aprovacao.service";
import AcaoItem from "./AcaoItem";

export default {
  name: "AprovacaoItem",
  components: {AcaoItem},
  props: {
    color: {type: String, required: true},
    processo: {type: Object, required: true},
    historico: {type: Object, required: true}
  },

  data() {
    return {
      dialogResultado: false,
      resultado: {},
      loading: false
    };
  },

  methods: {
    async realizarAprovacao() {
      let loader = this.$loading.show();
      await aprovacaoService.realizarAprovacao(this.processo.oid)
          .then((response) => {
            loader.hide();
            this.$emit("feito");
            this.resultado = {
              tipo: "success",
              msg: "Aprovação realizada com sucesso",
              detalhes: [
                `Candidatos Aptos: ${response.data.qtdCandidatosAptos}`,
                `Candidatos já aprovados: ${response.data.qtdJaAprovados}`,
                `Candidatos aprovados: ${response.data.qtdAprovados}`
              ]
            };
          }).catch((error) => {
            loader.hide();
            if (error.response.status === 409) {
              this.resultado = {
                tipo: "error",
                msg: error.response.data.msg,
                detalhes: error.response.data.detalhes
              };
            }
          });
      this.dialogResultado = true;
    },

    visualizarAprovacao() {
      this.$router.push({
        name: "InscricaoFiltro",
        params: { processoOid: this.processo.oid }
      });
    }
  }
};
</script>

<style scoped>

</style>
